import { Component, Input } from "@angular/core";
import { SurveyResponse } from "@app/models/survey";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";

@Component({
  selector: "app-list-table",
  templateUrl: "./list-table.component.html",
  styleUrls: ["./list-table.component.scss"],
})
export class ListTableComponent {
  @Input() list: SurveyResponse[];
  @Input() loading: boolean;
  @Input() hasExtraPadding: boolean = false;

  getIcon(npsClass: string) {
    switch (npsClass) {
      case "promoters":
        return "face-smile"; //"face-meh" // face-frown-slight
      case "detractors":
        return "face-frown-slight";
      case "passives":
      default:
        return "face-meh";
    }
  }

  getColor(npsClass: string) {
    switch (npsClass) {
      case "promoters":
        return "green";
      case "detractors":
        return "red";
      case "passives":
      default:
        return "yellow";
    }
  }

  openNewTab(result: SurveyResponse) {
    openLinkInNewTab(result.responseUrl);
  }
}
