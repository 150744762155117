import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as searchActions from "@app/core/components/search/ngrx/search.actions";
import { OfficeService } from "@app/core/ngrx/entity-services/office.service";
import { RegionService } from "@app/core/ngrx/entity-services/region.service";
import { ConfigService } from "@app/core/services/config/config.service";
import * as integrationActions from "@app/integrations/ngrx/integrations.actions";
import { Employee } from "@app/models";
import {
  ENTERING_DURATION,
  EXITING_DURATION,
  routeTransition,
} from "@app/shared/animations";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import {
  PRODUCT_NEWS,
  REGIONS,
  SUPPORT_WIDGET,
} from "@app/shared/config/utils/features";
import * as sharedActions from "@app/shared/ngrx/shared.actions";
import {
  loadObjectStatusRequest,
  loadObjectTypesRequest,
} from "@app/shared/ngrx/shared.actions";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import * as fromRouter from "../../../ngrx/router/router.selectors";
import { ScrollService } from "../../../services/scroll/scroll.service";

@Component({
  selector: "main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
  animations: [
    trigger("isNavVisibleChanged", [
      state("0", style({ marginLeft: "0" })),
      state("1", style({ marginLeft: "6rem" })),
      transition("0 => 1", animate(`${ENTERING_DURATION} ease-in`)),
      transition("1 => 0", animate(`${EXITING_DURATION} ease-out`)),
      transition("void => 1", animate(`${ENTERING_DURATION} ease-in`)),
    ]),
    trigger("sidebarAnimationStates", [
      state("void", style({ marginRight: "0" })),
      state("0", style({ marginRight: "0" })),
      state("1", style({ marginRight: "3rem" })),
      transition("void => 1", animate(`${ENTERING_DURATION} ease-in`)),
      transition("0 => 1", animate(`${ENTERING_DURATION} ease-in`)),
      transition("1 => void", animate(`${EXITING_DURATION} ease-out`)),
      transition("1 => 0", animate(`${EXITING_DURATION} ease-out`)),
    ]),
    routeTransition(),
  ],
})
export class MainPageComponent implements OnInit, OnDestroy {
  hasScrolled$: Observable<boolean>;
  isNavbarVisible: boolean;
  isLargeScreen: boolean;
  isMobile$: Observable<boolean>;
  sidebarAnimationStates$: Observable<boolean>;
  unsubscribe$: Subject<void> = new Subject<void>();
  isProductNewsEnabled$: Observable<boolean>;
  isSupportWidgetEnabled$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document,
    private store: Store<AppState>,
    private scrollService: ScrollService,
    private router: Router,
    private configService: ConfigService,
    private officeService: OfficeService,
    private regionService: RegionService
  ) {}

  ngOnInit(): void {
    this.initStoreObservables();
    this.fetchOfficesAndEmployees();
    this.loadObjectTypes();
    this.isProductNewsEnabled$ = this.store.pipe(
      select(getFeature(PRODUCT_NEWS)),
      map((feature: Feature) => feature.enabled)
    );
    this.isSupportWidgetEnabled$ = this.store.pipe(
      select(getFeature(SUPPORT_WIDGET)),
      map((feature: Feature) => feature.enabled)
    );
    this.store.dispatch(sharedActions.loadOffices({}));
    // Todo: Only fetch if region-feature is on.
    this.store
      .pipe(
        select(getFeature(REGIONS)),
        map((feature) => feature.enabled),
        filter((enabled) => !!enabled),
        first()
      )
      .subscribe(() =>
        this.store.dispatch(sharedActions.loadRegionsRequest({ params: {} }))
      );

    this.store.dispatch(integrationActions.fetchIntegrationsRequest());
    this.loadIntegrations();
    this.store
      .pipe(select(fromUser.getEmployee), takeUntil(this.unsubscribe$))
      .subscribe((e: Employee) => {
        if (e.eaEmployeeId) {
          this.store.dispatch(
            fromUser.loadAllEmployeesAndOfficesRequest({
              email: e.employeeEmail,
            })
          );
          this.store.dispatch(
            integrationActions.fetchExternalTipsIntegrationDescriptionRequest()
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadObjectTypes() {
    combineLatest([
      this.store.pipe(select(fromConfig.getCountry)),
      this.store.pipe(select(fromConfig.getObjectTypeIds)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([countryIso, objectTypeIds]) => {
        this.store.dispatch(
          loadObjectTypesRequest({ params: { countryIso, objectTypeIds } })
        );
        this.store.dispatch(loadObjectStatusRequest({ country: countryIso }));
      });
  }

  loadIntegrations() {
    this.configService.verifySearchIntegration();
    this.configService.verifyEmailProviderIntegration();
  }

  private fetchOfficesAndEmployees() {
    this.officeService.getWithQuery({}).subscribe();
    this.store
      .pipe(
        select(getFeature(REGIONS)),
        map((feature) => feature.enabled),
        filter((enabled) => !!enabled),
        first()
      )
      .subscribe(() => this.regionService.getWithQuery({}).subscribe());
  }

  initStoreObservables(): void {
    this.store
      .select((s) => s.navbar.isVisible)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isVisible) => (this.isNavbarVisible = isVisible));

    this.store
      .pipe(select(fromShared.isLargeScreen), takeUntil(this.unsubscribe$))
      .subscribe((_isLargeScreen) => {
        this.isNavbarVisible = _isLargeScreen;
        this.isLargeScreen = _isLargeScreen;
      });

    this.isMobile$ = this.store.pipe(
      select(fromShared.isMobile),
      takeUntil(this.unsubscribe$)
    );

    this.hasScrolled$ = this.scrollService.source$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.document.body.scrollTop > 0)
    );

    this.sidebarAnimationStates$ = combineLatest([
      this.store.pipe(
        select(fromRouter.getUrl),
        map((url) => url.indexOf("//sidebar") !== -1)
      ),
      this.store
        .pipe(select(fromSidebar.getTabs))
        .pipe(map((tabs) => tabs.length > 0)),
    ]).pipe(map(([sidebarUrl, hasTabs]) => sidebarUrl && hasTabs));
  }

  @HostListener("document:keydown", ["$event"])
  keypress(e: KeyboardEvent) {
    if (e.key.toLowerCase() === "escape") {
      this.store.dispatch(searchActions.hide());
      this.router.navigate(["/crm", { outlets: { sidebar: null } }]);
    } else if (e.key.toLowerCase() === "k" && e.ctrlKey) {
      this.router.navigate([
        "/crm",
        { outlets: { sidebar: ["root", "contacts", "new"] } },
      ]);
    } else if (e.key.toLowerCase() === "m" && e.ctrlKey) {
      this.router.navigate([
        "/crm",
        { outlets: { sidebar: ["root", "send-message"] } },
      ]);
    }
  }

  getRouteState(outlet: RouterOutlet): string {
    return outlet.activatedRouteData.state;
  }
}
